<template lang="pug">
#ChatModal
  .container
    svg(xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink")
      defs
        clipPath(
          id="svgClipPath"
          style="transform: scale(1)"
          clipPathUnits="objectBoundingBox")
          path(
            :d="path"
            id="path-id")
    .chat__content
      .overlay__background
      #conversation-wrapper
        #conversation-container
          .message-container
            .message__sent
              .message-top-information
                .top-information__receiver-has-read
                .top-information__created 12:30
              .content This chat will be used as a modal pop up!
                .attachment-links
          .message-container
            .message__sent
              .message-top-information
                .top-information__receiver-has-read
                .top-information__created 12:30
              .content I used an svg to set the rounded borders :)
                .attachment-links
          .message-container
            .message__sent
              .message-top-information
                .top-information__receiver-has-read
                .top-information__created 12:30
              .content What do you think?
                .attachment-links
          .message-container
            .message__received
              .message-top-information
                .top-information__receiver-has-read
                .top-information__created 12:30
              .content I like it... quite original I must say :)
          .message-container
            .message__received
              .message-top-information
                .top-information__created 12:30
                .top-information__receiver-has-read
              .content Keep on!
          .message-container
            .message__sent
              .message-top-information
                .top-information__receiver-has-read
                .top-information__created 12:30
              .content Just need to finish implementing it.
                .attachment-links
          //#has-read-all-messages
          //  #height-indicator
      #send-message-clip-path
      #write-message
        input(
          id="write-message-input"
          type="text"
          placeholder="Write your message"
          v-model="write_message")
      #send-message
        .send-message-button(
          @click="send_message")
</template>

<script>
export default {
  name: 'ChatModal',
  setup () {

    let write_message = 'Almost there';
    let currentUserID = '';
    let path = `M 0.0414 0.0257 C 0.1941 0.0086 0.3469 0 0.4996 0 C 0.6523 0 0.8051 0.0086 0.9578 0.0257
      L 0.9578 0.0257 C 0.9673 0.0267 0.9748 0.0349 0.9758 0.0452 C 0.9914 0.1968 0.9992 0.3484 0.9992 0.5
      C 0.9992 0.6516 0.9914 0.8032 0.9758 0.9548 L 0.9758 0.9548 C 0.9748 0.9651 0.9673 0.9732 0.9578 0.9743
      C 0.8051 0.9914 0.6523 1 0.4996 1 C 0.3469 1 0.1941 0.9914 0.0414 0.9743 L 0.0414 0.9743
      C 0.0319 0.9732 0.0244 0.9651 0.0234 0.9548 C 0.0078 0.8032 0 0.6516 0 0.5
      C 0 0.3484 0.0078 0.1968 0.0234 0.0452 L 0.0234 0.0452 C 0.0244 0.0349 0.0319 0.0267 0.0414 0.0257 Z`;
    let id = 'ssss';


    function send_message () {

    }


    return {
      send_message,

      write_message,
      currentUserID,
      path,
      id,
    };
  },
};
</script>

<style lang="stylus" scoped>
#ChatModal
  position relative
  min-height 100vh
  display flex
  align-items center
  justify-content center
  transform scale(.8)
  .container
    position relative
    z-index 1000
    -webkit-clip-path url(#svgClipPath)
    clip-path url(#svgClipPath)
    border-radius 0
    padding 0
    border 0
    outline 0
    pointer-events auto
    overflow hidden
    > svg
      display block
      position absolute
    .chat__content
      width 600px
      height 800px
      overflow hidden
      position relative
      background url(../../public/chat-modal/chat-bg.png) no-repeat center;
      background-size cover
      .overlay__background
        position absolute
        top 0
        width 100%
        height 50%
        z-index 100
        background-image url(../../public/chat-modal/contact-modal-illustration.png)
        background-repeat no-repeat
        background-size 45% auto
        background-position top left
        background-clip padding-box
      #conversation-wrapper
        position absolute
        z-index 200
        top 0
        left 0
        bottom 75px
        right -10px
        overflow-x hidden
        overflow-y auto
        #conversation-container
          position relative
          .message-container
            position relative
            display flex
            padding 30px 0 0 0
            .new-messages-display
              background yellow
              height 20px
            .dayIndicator
              background rosybrown
              text-align center
              color white
              height 20px
            .has_unread_indicator
              background blueviolet
              height 20px
              width 20px
              position absolute
              bottom 5px
              left 5px
              color white
            .message__received, .message__sent
              .message-top-information
                padding 0 30px 10px 0
                display flex
                .top-information__created
                  font 500 11px Averta
                  color white
                .top-information__receiver-has-read
                  position relative
                  top -2px
                  color red
              .content
                font-size 13px
                color #525F6F
                display inline-flex
                padding 7px 20px
            .message__received
              margin-right auto
              .message-top-information
                margin-left 20px
                justify-content flex-start
                .top-information__receiver-has-read
                  display none
              .content
                background linear-gradient(150deg, #ffbf85, #fff0bc)
                padding 10px 17px 10px 25px
                border-top-left-radius 0
                border-top-right-radius 12em 10em
                border-bottom-right-radius 12em 10em
                border-bottom-left-radius 0
                margin-right 100px
                color black
                .attachment-links
                  text-decoration none
                  color royalblue
                  display inline-block
            .message__sent
              margin-left auto
              text-align right
              .message-top-information
                justify-content flex-end
                .top-information__receiver-has-read
                  height 15px
                  width 15px
                  margin 0 6px 0 0
                  background url(../../public/chat-modal/check_3.svg) no-repeat
              .content
                padding 10px 35px 10px 17px // 35px is to compensate the #conversation-wrapper right - 10px
                border-top-left-radius 12em 10em
                border-top-right-radius 0
                border-bottom-right-radius 0
                border-bottom-left-radius 12em 10em
                margin-left 100px
                color black
                background linear-gradient(150deg, #e5d5ff, #afaaf1)
                background-color #1B84D9
                .attachment-links
                  text-decoration none
                  color blue
                  display inline-block
          #has-read-all-messages
            position relative
            height 30px
            background orange
            #height-indicator
              position absolute
              bottom 0
              left 0
              height 300px
              width 10px
              background green
      #send-message-clip-path
        // box-shadow: 10px 16px 40px 0 rgba(255, 255, 255, 0.7), inset 0 0 10px rgba(32, 32, 32, .32);
        background-image url(../../public/chat-modal/chat-bg.png)
        background-position center -10px
        background-repeat no-repeat
        background-color white
        background-size 100%
        clip-path url(#svgClipPath)
        position absolute
        top 700px
        left 7px
        right 7px
        height 600px
        opacity 0.7
      #write-message
        border-bottom 1px solid #c9d3ec
        position absolute
        left 50px
        bottom 30px
        right 100px
        height 40px
        #write-message-input
          background transparent
          font 400 15px Averta
          outline none
          border none
          color white
          height 100%
          width 100%
          &::placeholder
            color #b6b6b6
      #send-message
        //box-shadow 10px 16px 40px 0 rgba(255, 84, 117, .46)
        position absolute
        right 35px
        bottom 25px
        width 50px
        height 50px
        background-image url("../../public/chat-modal/send(4).svg")
        background-repeat no-repeat
        background-position center
        background-size 100%
        transform rotateZ(10deg)
        border-radius 50px
        cursor pointer
        .send-message-button
          height 100%
          width 100%
</style>